@if(loading) {
<div class="loading-page">
  <mat-card class="spinner-card">
    <mat-spinner [diameter]="72"></mat-spinner>
    <div class="text-default mt-4">{{ 'commonInformation.loader' | translate }}</div>
  </mat-card>
</div>
}

<router-outlet></router-outlet>
