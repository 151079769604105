import { Subscription } from 'rxjs'

export class ComponentUtils {
  static removeSubscriptions(subscriptions: Subscription[]): void {
    if (subscriptions && subscriptions.length > 0) {
      for (const subscription of subscriptions) {
        if (subscription) {
          subscription.unsubscribe()
        }
      }
    }
  }
}
