import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { ClosureInventoryWorkDate } from '../shared/models/task-tracker.model'

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  workDate: BehaviorSubject<Date> = new BehaviorSubject<Date>(new Date())
  businessDate: BehaviorSubject<{ bussinesDate: Date; setDate: boolean }> = new BehaviorSubject<{
    bussinesDate: Date
    setDate: boolean
  }>({
    bussinesDate: new Date(),
    setDate: false,
  })
  closureInventoryWorkDate: BehaviorSubject<ClosureInventoryWorkDate> = new BehaviorSubject<ClosureInventoryWorkDate>({
    isSelectedDateOpen: false,
    openWorkDate: '',
  })
  storeZone: BehaviorSubject<number> = new BehaviorSubject<number>(0)
  loadingSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  loadingMap: Map<string, boolean> = new Map<string, boolean>()
  workDate$ = this.workDate.asObservable()
  storeZone$ = this.storeZone.asObservable()

  setLoading(loading: boolean, url: string): void {
    if (!url) {
      throw new Error('The request URL must be provided to the LoadingService.setLoading function')
    }
    if (loading === true) {
      this.loadingMap.set(url, loading)
      this.loadingSub.next(true)
    } else if (loading === false && this.loadingMap.has(url)) {
      this.loadingMap.delete(url)
    }
    if (this.loadingMap.size === 0) {
      this.loadingSub.next(false)
    }
  }
}
