import { Injectable, inject } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer } from '@angular/platform-browser'
import {
  ADD_TABLE_ICON,
  INVENTORIES_ICON,
  NOTIFICATIONS_ICON,
  SALES_ICON,
  THUMBUP_ICON,
  USERS_ICON,
} from '../../../../assets/icon'

@Injectable({
  providedIn: 'root',
})
export class IconRegisterService {
  private iconRegistry = inject(MatIconRegistry)
  private sanitizer = inject(DomSanitizer)

  registerIcons(): void {
    this.iconRegistry.addSvgIconLiteral('thumbs-up', this.sanitizer.bypassSecurityTrustHtml(THUMBUP_ICON))
    this.iconRegistry.addSvgIconLiteral('users', this.sanitizer.bypassSecurityTrustHtml(USERS_ICON))
    this.iconRegistry.addSvgIconLiteral('sarbo-sales', this.sanitizer.bypassSecurityTrustHtml(SALES_ICON))
    this.iconRegistry.addSvgIconLiteral('inventories', this.sanitizer.bypassSecurityTrustHtml(INVENTORIES_ICON))
    this.iconRegistry.addSvgIconLiteral('notifications', this.sanitizer.bypassSecurityTrustHtml(NOTIFICATIONS_ICON))
    this.iconRegistry.addSvgIconLiteral('added-row', this.sanitizer.bypassSecurityTrustHtml(ADD_TABLE_ICON))
  }
}
