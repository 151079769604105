<div class="flex items-center py-2 px-4 bg-white rounded-3xl">
  <!-- Work Date -->
  @if (showCalendar) {
  <div class="work-date-ctn" [matMenuTriggerFor]="calendar">
    <button class="p-0 m-0" mat-icon-button>
      <mat-icon class="text-primary">calendar_month</mat-icon>
    </button>
    <p class="text-primary text-18-400 w-28">{{ selectedWorkDay | date : 'E, d MMM': undefined :
      languageSelected.toLowerCase() | titlecase }}</p>
    <mat-menu class="w-96 mt-[12px]" #calendar="matMenu">
      <mat-calendar (click)="$event.stopPropagation()" [maxDate]="today" #calendar [selected]="selectedWorkDay"
        (selectedChange)="selectWorkDate($event)">
      </mat-calendar>
    </mat-menu>
  </div>
  }
  <!-- Store Menu -->
  <mat-icon class="material-symbols-outlined"
    [ngClass]="otherAvailableStores.length > 0 ? 'text-primary ' : 'text-lightblack'">
    location_on
  </mat-icon>
  <div [matMenuTriggerFor]="storesMenu">
    <button mat-button [disabled]="otherAvailableStores.length === 0">
      <span class="text-18-400" [ngClass]="otherAvailableStores.length > 0 ? 'text-primary' : 'text-lightblack'">{{
        currentStore.storeCode + ' - ' + currentStore.storeName
        }}</span>
    </button>
    <mat-menu class="mt-[10px]" #storesMenu="matMenu" [hidden]="otherAvailableStores.length === 0">
      @for (store of otherAvailableStores; track store) {
      <button class="hover:bg-light" mat-menu-item (click)="changeStore(store.storeId)">
        {{ store.storeCode + ' - ' + store.storeName }}
      </button>
      }
    </mat-menu>
  </div>

  <!-- V-line -->
  <div class="v-line"></div>

  <!-- Progress Menu  -->
  <mat-icon class="material-symbols-outlined text-primary" [matMenuTriggerFor]="progressMenu" (click)="getTasks()">
    history_toggle_off
  </mat-icon>
  <mat-menu class="mt-[24px] p-4 w-80" #progressMenu="matMenu" xPosition="before">
    <p class="text-default font-semibold text-xl">{{ 'profileMenu.taskTracker.labels.pendingTasks' | translate }}</p>
    <p class="text-default mt-3 text-lg">
      {{ getTasksStatus(tabSelected()) }}
    </p>
    <div class="bg-disabled rounded-xl w-fit mt-4" (click)="$event.stopPropagation()">
      @for (group of groups; track group.name) {
      <button type="button" class="px-4 py-2 rounded-xl" [ngClass]="{
          'bg-disabled text-disabled': tabSelected() !== group.name,
          'bg-primary text-white': tabSelected() === group.name
        }" (click)="selectTab(group.name)">
        {{ getTranslate(group.name, 'tabs') }}
      </button>
      }
    </div>
    <div>
      @for(task of tasks; track task) { @if (task.group === tabSelected()) {
      <div class="px-2 flex items-center justify-between mt-4 even:bg-secondary rounded-xl h-12">
        <div class="flex items-center">
          <mat-icon class="material-symbols-outlined"
            [ngClass]="{ 'text-primary': task.completed, 'text-warning': !task.completed }">{{ task.completed ?
            'check_circle' : 'history_toggle_off' }}</mat-icon>
          <p class="ml-2 text-18-400">{{ getTranslate(task.name, 'tasks') }}</p>
        </div>
      </div>
      } }
    </div>
  </mat-menu>

  <!-- Notification -->
  <mat-icon class="material-symbols-outlined mx-4 text-default"> notifications </mat-icon>

  <mat-icon class="material-symbols-outlined mr-4 text-default cursor-pointer hover:text-primary"
    [sarboDropdownTriggerFor]="dropdown" [position]="connectedPosition">
    language
  </mat-icon>
  <sarbo-dropdown-custom #dropdown>
    <ul class="flex flex-col gap-1 pr-1 overflow-auto max-h-[300px]">
      @for(language of availableLanguages$ | async; track language){
      <li class="flex items-center p-2 rounded-[9px] hover:bg-light leading-3 cursor-pointer" [ngClass]="{
          'bg-disabled text-disabled cursor-not-allowed hover:bg-disabled': language.code === selectedLanguage
        }" (click)="languageChangeAttempt(language)">
        <p class="mx-2">{{ language.name }}</p>
      </li>
      }
    </ul>
  </sarbo-dropdown-custom>

  <!-- Company Initial Letters  -->
  <div mat-button class="flex items-center justify-center w-12 h-12 bg-darkcolor rounded-full">
    <span class="text-base font-semibold text-white">{{ dataService.signalFirstName()[0] | uppercase }} {{
      dataService.signalLastName()[0] | uppercase }}</span>
  </div>

  <!-- Avatar Menu -->
  <mat-icon [matMenuTriggerFor]="userMainMenu">keyboard_arrow_down</mat-icon>
  <mat-menu class="mt-[24px]" #userMainMenu="matMenu" xPosition="before">
    <div>
      @if (availableCompanies.length > 1) {
      <button mat-menu-item class="cursor-pointer" routerLink="/company-and-store">
        <mat-icon class="mr-2" svgIcon="company"></mat-icon>
        {{ 'profileMenu.labels.changeCompany' | translate }}
      </button>
      }
    </div>
    <div>
      <button mat-menu-item class="cursor-pointer hover:bg-light" (click)="logout()">
        <mat-icon class="material-symbols-outlined text-default"> door_open </mat-icon>
        {{ 'profileMenu.labels.logout' | translate }}
      </button>
    </div>
  </mat-menu>
</div>