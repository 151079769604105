import { Injectable, inject } from '@angular/core'
import { LoginRequest, LoginResponse, LoginResponseError, CheckPinResponse } from '../../models/login.model'
import { Observable } from 'rxjs'
import { DataService } from '../../../shared/services/data/data.service'
import { ValidatePinRequest } from '../../models/validate-pin.model'
import { Router } from '@angular/router'
// import { TranslateService } from '@ngx-translate/core'
import { AppComponent } from '../../../app.component'
import { TranslateService } from '@ngx-translate/core'
import { SnackbarComponent } from '../../../shared/components/snackbar/snackbar.component'
import { Company, CompanyWithStores, Store } from '../../models/company-store.model'
import { AuthService } from '../../../../app/services/auth.service'
import * as Sentry from '@sentry/angular'

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private dataService = inject(DataService)
  private snack = inject(SnackbarComponent)
  private auth = inject(AuthService)
  private translate = inject(TranslateService)

  @Sentry.TraceMethod()
  login(credentials: LoginRequest): Observable<LoginResponse> {
    return this.auth.login(credentials)
  }

  isUserLoggedIn(): boolean {
    return this.auth.isUserLoggedIn()
  }

  @Sentry.TraceMethod()
  async refreshToken(): Promise<void> {
    return this.auth.refreshToken()
  }

  @Sentry.TraceMethod()
  async logout(): Promise<void> {
    return this.auth.logout()
  }

  forgotPassword(username: string): Observable<{ email: string }> {
    return this.auth.forgotPassword(username)
  }

  validatePin(validatePinRequest: ValidatePinRequest): Observable<CheckPinResponse> {
    return this.auth.validatePin(validatePinRequest)
  }

  changePassword(password: string): Observable<LoginResponse> {
    return this.auth.changePassword(password)
  }

  submitNewPin(pin: string): Observable<LoginResponse> {
    return this.auth.submitNewPin(pin)
  }

  validateMFA(code: string): Observable<LoginResponse> {
    return this.auth.validateMFA(code)
  }

  resendMFA(): Observable<LoginResponseError> {
    return this.auth.resendMFA()
  }

  userLoggedIn(response: LoginResponse, app: AppComponent, router: Router): void {
    this.setData(response)
    const previousUsername = this.dataService.signalUsername() ?? ''
    const lastUrl = this.dataService.signalLastUrl() ?? ''
    app.inactiveSessionTimeout = response.inactiveSessionTimeout
    if (previousUsername === response.username) {
      this.auth.isIdleWatching.next(true)
      if (lastUrl) {
        router.navigate([lastUrl]).catch((err) => {
          console.error('Navigation error:', err)
        })
      } else {
        router.navigate(['company-and-store']).catch((err) => {
          console.error('Navigation error:', err)
        })
      }
    } else {
      router.navigate(['company-and-store']).catch((err) => {
        console.error('Navigation error:', err)
      })
    }
  }

  setData(data: LoginResponse): void {
    let lastCompany: Company
    let lastStore: Store


    
    // START: Workaround meanwhile the login API is not sending the lastCompany.companyNumericCode, lastStore.storeCode and lastStore.storeTimezone
    const userLocations: CompanyWithStores[] = this.dataService.signalUserLocations() ?? []
    
    let companyNumericCode = 0
    let storeCode = 0
    let storeTimezone = 99
    if (userLocations.length > 0) {
      const lastLocationCompany = userLocations.find((company) => company.companyId === data.lastCompany.id)
      
      companyNumericCode = lastLocationCompany?.companyNumericCode ?? 0
      
      const lastLocationStore = lastLocationCompany?.stores.find((store) => store.storeId === data.lastStore.id)
      storeCode = lastLocationStore?.storeCode ?? 0
      storeTimezone = lastLocationStore?.storeTimezone ?? 99
    }
    // END: Workaround meanwhile the login API is not sending the lastCompany.companyNumericCode, lastStore.storeCode and lastStore.storeTimezone



    Sentry.setUser(data);
    if (data.lastCompany) {
      lastCompany = { companyId: data.lastCompany.id, companyNumericCode: companyNumericCode, companyName: data.lastCompany.name }
      this.dataService.setItem({ key: 'lastCompany', value: lastCompany })
    }
    if (data.lastStore) {
      lastStore = { storeId: data.lastStore.id, storeName: data.lastStore.name, storeCode: storeCode, storeTimezone: storeTimezone }
      this.dataService.setItem({ key: 'lastStore', value: lastStore })
    }

    this.dataService.setItem({ key: 'refreshToken', value: data.refreshToken })
    this.dataService.setItem({ key: 'authToken', value: data.token })
    this.dataService.setItem({ key: 'inactiveSessionTimeout', value: data.inactiveSessionTimeout })
    this.dataService.setItem({ key: 'username', value: data.username })
    // this.dataService.setItem({ key: 'language', value: data.language })
    this.dataService.updateSignals()
  }

  handleLoginError(error: LoginResponseError, router: Router): void {
    switch (error.reason) {
      case 'mfa':
        this.dataService.setItem({ key: 'tempToken', value: error.tempToken })
        router.navigate(['two-factor-authentication']).catch((err) => {
          console.error('Navigation error:', err)
        })
        break
      case 'pin':
        this.dataService.setItem({ key: 'tempToken', value: error.tempToken })
        router.navigate(['new-pin']).catch((err) => {
          console.error('Navigation error:', err)
        })
        break
      case 'changePassword':
      case 'passwordExpired':
        this.snack.openSnackBar(this.translate.instant(`login.toasts.${error.reason}`), 'X', 'error', 5000)
        this.dataService.setItem({ key: 'tempToken', value: error.tempToken })
        router.navigate(['/change-password']).catch((err) => {
          console.error('Navigation error:', err)
        })
        break
      case 'tempTokenExpired':
        this.snack.openSnackBar(this.translate.instant('login.toasts.tempTokenExpired'), 'X', 'error', 5000)
        this.dataService.removeItem('tempToken')
        router.navigate(['']).catch((err) => {
          console.error('Navigation error:', err)
        })
        break
    }
  }
}
