import { CommonModule } from '@angular/common'
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core'
import { FormGroup, ReactiveFormsModule } from '@angular/forms'
import { MatButton, MatButtonModule } from '@angular/material/button'

@Component({
  selector: 'sarbo-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatButtonModule],
})
export class ButtonComponent implements AfterViewInit {
  @ViewChild('btnRef') buttonRef: MatButton | undefined

  @Input() formGroup: FormGroup = new FormGroup({})
  @Input() color: 'primary' | 'accent' | 'warn' | '' = 'primary'
  @Input() buttonText = ''
  @Input() disabled = false
  @Input() type: 'button' | 'submit' | 'menu' | 'reset' = 'button'
  @Input() component: 'raised' | 'stroked' | 'flat' | 'icon' | 'fab' | 'mini-fab' = 'flat'
  @Input() componentClass: string | undefined = ''
  @Input() focus: boolean | undefined = false

  @Output() clickEvent = new EventEmitter()

  ngAfterViewInit(): void {
    if (this.focus) {
      this.buttonRef?.focus()
    }
  }

  onClick(event: Event): void {
    if(!this.disabled) {
      this.clickEvent.emit(event)
    }
  }
}
