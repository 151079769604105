import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { environment } from '../../../../environments/environment'
import { BehaviorSubject, firstValueFrom } from 'rxjs'
import { Language } from '../../../shared/types/language.type'

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private http = inject(HttpClient)
  languages = new BehaviorSubject<Language[] | undefined>(undefined)

  async getLanguages(): Promise<void> {
    const options = await firstValueFrom(this.http.get<Language[]>(environment.translationsUrl))
    this.languages.next(options)
  }

  async getLanguage(code: string): Promise<Language | undefined> {
    if (this.languages.value === undefined) {
      await this.getLanguages()
    }
    return this.languages.value?.find((lang) => lang.code.toUpperCase() === code.toUpperCase()) as Language
  }
}
