/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface DeactivationGuarded {
  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean;
}


@Injectable()
export class CanDeactivateGuard implements CanDeactivate<any> {
  canDeactivate(component: DeactivationGuarded): boolean | Observable<boolean> | Promise<boolean> {

    return component.canDeactivate ? component.canDeactivate() : true;
  }
}
