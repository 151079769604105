import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'

@Component({
  selector: 'sarbo-layout-landing',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './layout-landing.component.html',
  styleUrl: './layout-landing.component.scss',
})
export class LayoutLandingComponent {}
