import { bootstrapApplication } from '@angular/platform-browser'
import { appConfig } from './app/app.config'
import { AppComponent } from './app/app.component'
import * as Sentry from '@sentry/angular'
import { environment } from './environments/environment';

if (['dev', 'qa', 'uat'].includes(environment.envName)) {
  Sentry.init({
    dsn: "https://328d22fa3384e9928eabc87cb4c8dbe2@o4507527286030336.ingest.us.sentry.io/4507527427850240",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.httpClientIntegration(),
      Sentry.captureConsoleIntegration(),
      Sentry.breadcrumbsIntegration(),
      Sentry.httpContextIntegration(),
      Sentry.browserApiErrorsIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
    environment: environment.envName,
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0.3,
    replaysOnErrorSampleRate: 1.0,
  });
}


bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err))
