<div class="flex flex-col gap-1 text-base font-semibold p-2 rounded-lg bg-white -ml-3 w-[245px]">
  @for (menuItem of menuItems; track menuItem) { @if (!menuItem.routerLink) {
  <div
    class="flex items-center justify-between gap-2 p-2 rounded-lg hover:bg-light text-lightblack"
    (click)="selectedMenuItem === menuItem.text ? (selectedMenuItem = '') : (selectedMenuItem = menuItem.text)"
    [ngClass]="{ 'bg-primary text-white': menuItem.text === selectedMenuItem }"
    [sarboPermissionsNeeded]="menuItem.permissionsNeeded"
  >
    <div class="flex items-center gap-2">
      <img
        [src]="'../../../../assets/icons/' + menuItem.icon + '.svg'"
        [alt]="menuItem.text + ' Icon'"
        [ngClass]="{ 'svgcolor-white': menuItem.text === selectedMenuItem }"
        class="h-6 w-6"
      />
      <span>{{ menuItem.text | translate }}</span>
    </div>
    @if (menuItem.text === selectedMenuItem) {
    <img src="../../../../../assets/icons/expand_less.svg" [alt]="menuItem.text + ' Icon'" class="svgcolor-white" />
    } @else {
    <img src="../../../../../assets/icons/expand_more_2b2c2c.svg" [alt]="menuItem.text + ' Icon'" />
    }
  </div>
  } @else {
  <div
    class="flex items-center gap-2 p-2 rounded-lg hover:bg-light text-lightblack"
    [routerLink]="menuItem.routerLink"
    routerLinkActive="bg-primary text-white"
    [sarboPermissionsNeeded]="menuItem.permissionsNeeded"
    (click)="selectedMenuSubitem.emit({ text: '', routerLink: '', icon: '', permissionsNeeded: [] })"
  >
    <img
      [src]="'../../../../assets/icons/' + menuItem.icon + '.svg'"
      [alt]="menuItem.text + ' Icon'"
      routerLinkActive="svgcolor-white"
      class="h-6 w-6"
    />
    <span>{{ menuItem.text | translate }}</span>
  </div>
  } @if (menuItem.text === selectedMenuItem) { @for (menuSubitem of menuItem.subitems; track menuSubitem) {
  <div
    class="flex items-center gap-2 px-4 py-2 rounded-lg hover:bg-light text-lightblack"
    [routerLink]="menuSubitem.routerLink"
    routerLinkActive="bg-primary text-white"
    [sarboPermissionsNeeded]="menuSubitem.permissionsNeeded"
    (click)="selectedMenuSubitem.emit(menuSubitem)"
  >
    <img
      class="h-6 w-6"
      [src]="'../../../../assets/icons/' + menuSubitem.icon + '.svg'"
      [alt]="menuSubitem.text + ' Icon'"
      routerLinkActive="svgcolor-white"
    />
    <span> {{ menuSubitem.text | translate }}</span>
  </div>
  } } }
</div>
