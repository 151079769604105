/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core'
import { TranslateLoader } from '@ngx-translate/core'
import { Observable, catchError, from, switchMap } from 'rxjs'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { TranslationService } from '../shared/services/translation/translation.service'
import { environment } from '../..//environments/environment'

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {
  private http = inject(HttpClient)
  private translationService = inject(TranslationService)
  headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' })
  getTranslation(lang: string): Observable<any> {
    if (environment.envName === 'local') {
      return this.http.get(`./assets/locales/${lang}.json`)
    }
    return from(this.translationService.getLanguage(lang)).pipe(
      switchMap((language) => {
        return this.http.post(
          `${environment.translationsUrl}/select`,
          {
            language: language?.code,
          },
          { headers: this.headers, withCredentials: false },
        )
      }),
      catchError((error) => {
        console.error(error)
        return this.http.get(`./assets/locales/${lang}.json`)
      }),
    )
  }
}
