import { Injectable, inject } from '@angular/core'
import { CompanyWithStores, LastLocationRequest, EmployeeInfo } from '../../models/company-store.model'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { environment } from '../../../../environments/environment'
import { TStoreCompanyResponse } from '../../../shared/domain/types/storeCompany.type'

@Injectable({
  providedIn: 'root',
})
export class CompaniesAndStoresService {
  private http = inject(HttpClient)

  getUserLocations(): Observable<CompanyWithStores[]> {
    return this.http.get<CompanyWithStores[]>(`${environment.tmpUserManagement}/users/companies/stores`)
  }

  updateLastLocation(username: string, lastLocation: LastLocationRequest): Observable<TStoreCompanyResponse> {
    return this.http.post<TStoreCompanyResponse>(
      environment.userManagement + `/${username}` + '/set-store',
      lastLocation,
    )
  }

  getEmployeeInfoByUserName(userName: string): Observable<EmployeeInfo> {
    return this.http.get<EmployeeInfo>(`${environment.userManagement}/${userName}/employee`)
  }
}
