/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, inject } from '@angular/core'
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http'
import { Observable, throwError } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { LoadingService } from './loading.service'
import { DataService } from '../shared/services/data/data.service'
import { AuthService } from '../services/auth.service'

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  loadingService = inject(LoadingService)
  dataService = inject(DataService)
  private authService = inject(AuthService)
  private lastRequestTimeKey = 'lastRequestTime'

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    const skipLoadingSpinner = request.headers.has('skipLoadingSpinner')

    if (!skipLoadingSpinner) {
      this.loadingService.setLoading(true, request.url)
    } else {
      request = request.clone({
        headers: request.headers.delete('skipLoadingSpinner'),
      })
    }

    if (this.isLastRequestTimeGreaterThan(30)) {
      this.authService.logout()
      sessionStorage.clear()

      return throwError('Session expired')
    } else {
      this.setLastRequestTime()
      const companyId = this.dataService.signalLastCompany().companyId ?? 0
      const storeId = this.dataService.signalLastStore().storeId ?? 0
      const username = this.dataService.signalUsername()
      const storeTimezone = Number(this.dataService.signalStoreTimezone() || 0)
      const excludeCompanyInfoHeader = request.headers.has('excludeCompanyInfoHeader')
      const authToken = this.dataService.getItem('authToken')

      let workDateWithTimezone = ''
      if (this.loadingService.workDate.value.toDateString() !== new Date().toDateString()) {
        let month: string | number = this.loadingService.workDate.value.getUTCMonth() + 1
        month = month >= 10 ? month : `0${month}`
        let day: string | number = this.loadingService.workDate.value.getUTCDate()
        day = day >= 10 ? day : `0${day}`
        const year = this.loadingService.workDate.value.getUTCFullYear()
        workDateWithTimezone = `${year}-${month}-${day}T00:00:00`
      } else {
        workDateWithTimezone = this.createDateInTimeZone(storeTimezone)
      }

      if (!excludeCompanyInfoHeader) {
        request = request.clone({
          setHeaders: {
            'x-company-id': companyId.toString(),
            'x-store-id': storeId.toString(),
            'x-work-date': workDateWithTimezone,
            'x-username': username,
            'x-client-timezone': String(storeTimezone),
            Authorization: `Bearer ${authToken}`,
          },
        })
      } else if (excludeCompanyInfoHeader) {
        request = request.clone({
          headers: request.headers.delete('excludeCompanyInfoHeader'),
        })
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${authToken}`,
          },
        })
      }

      return this.handleRequest(next, request)
    }
  }

  setLastRequestTime(): void {
    const currentTime = new Date().getTime()
    sessionStorage.setItem(this.lastRequestTimeKey, currentTime.toString())
  }

  isLastRequestTimeGreaterThan(minutes: number): boolean {
    const lastRequestTime = sessionStorage.getItem(this.lastRequestTimeKey)
    if (lastRequestTime) {
      const currentTime = new Date().getTime()
      const differenceInMinutes = (currentTime - parseInt(lastRequestTime, 10)) / 60000
      return differenceInMinutes > minutes
    }
    return false
  }

  handleRequest(next: HttpHandler, request: HttpRequest<unknown>): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        this.loadingService.setLoading(false, request.url)
        return throwError(() => {
          this.errorHandle(error)
          return error
        })
      }),
      map((evt: HttpEvent<unknown>) => {
        if (evt instanceof HttpResponse) {
          this.loadingService.setLoading(false, request.url)
        }
        return evt
      }),
    )
  }

  async errorHandle(error: HttpErrorResponse): Promise<void> {
    if (
      (error.status === 401 || error.status === 403) &&
      [
        'authenticate',
        'log-out',
        'forgot-password',
        'change-password',
        'new-mfa',
        'valid-mfa',
        'set-pin',
        'check-pin',
      ].some((url) => error.url?.includes(url)) === false
    ) {
      await this.authService.logout()
    }
  }

  createDateInTimeZone(storeTimezone: number): string {
    const nowUTC = new Date()
    const offset = storeTimezone * 60
    const utcTime = nowUTC.getTime()
    const desiredTime = utcTime + offset * 60000
    const utcDateOffSet = new Date(desiredTime)
    const year = utcDateOffSet.getUTCFullYear()
    const month = String(utcDateOffSet.getUTCMonth() + 1).padStart(2, '0')
    const day = String(utcDateOffSet.getUTCDate()).padStart(2, '0')
    const hours = String(utcDateOffSet.getUTCHours()).padStart(2, '0')
    const minutes = String(utcDateOffSet.getUTCMinutes()).padStart(2, '0')
    const seconds = String(utcDateOffSet.getUTCSeconds()).padStart(2, '0')
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
  }
}
