<div [formGroup]="formGroup" class="{{ componentClass }}">
  @switch (component) { @case ('stroked') {
  <button
    mat-stroked-button
    #btnRef="matButton"
    [color]="color"
    [type]="type"
    (click)="onClick($event)"
    [disabled]="disabled"
    class="w-full"
  >
    {{ buttonText }}
  </button>
  } @default {
  <button
    mat-flat-button
    #btnRef="matButton"
    [color]="color"
    [type]="type"
    (click)="onClick($event)"
    [disabled]="disabled"
    class="w-full"
  >
    {{ buttonText }}
  </button>
  } }
</div>
