import { Component } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterLinkActive, RouterModule } from '@angular/router'
import { DropdownMenuComponent } from '../../components/dropdown-menu/dropdown-menu.component';
import { MainNavBarComponent } from '../../components/main-nav-bar/main-nav-bar.component';

@Component({
    selector: 'sarbo-app-layout',
    standalone: true,
    templateUrl: './app-layout.component.html',
    imports: [CommonModule, RouterModule, DropdownMenuComponent, MainNavBarComponent, RouterLinkActive]
})
export class AppLayoutComponent {}
