import { inject, Injectable } from '@angular/core'
import { DataService } from '../data/data.service'
// import { fromEvent } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  dataService = inject(DataService)

  constructor() {}

  preventBackButton(): void {
    // history.pushState(null, '', window.location.href)
    // fromEvent(window, 'popstate').subscribe(() => {
    //   history.pushState(null, '', window.location.href)
    // })
    // console.info('Prevent back button')
  }

  setTimeWhitStoreTimeZone(date: string): string {
    const storeTimezone = Number(this.dataService.signalStoreTimezone() || 0)
    const time = date.substring(11, 19)
    if (time === '00:00:00') {
      return '00:00'
    }
    const hours = Number(time.substring(0, 2))
    const minutes = time.substring(3, 5)
    let hoursWithTimezone = hours + storeTimezone

    if (hoursWithTimezone < 0) {
      hoursWithTimezone += 24
    }

    return `${hoursWithTimezone}:${minutes}`
  }

  formatDateTimeZone(completedDate: string): string {
    const date = completedDate.substring(0, 10)
    const year = date.substring(0, 4)
    const month = date.substring(5, 7)
    const day = date.substring(8, 10)
    return `${day}/${month}/${year}`
  }
}
