import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { PermissionBasedAccessDirective } from '../../../directives/permission-based-access.directive'
import { TranslateModule } from '@ngx-translate/core'

export interface MainNavBarMenuItem {
  text: string
  icon: string
  routerLink?: string
  permissionsNeeded: string[]
  subitems?: MainNavBarMenuSubitem[]
}

export interface MainNavBarMenuSubitem {
  text: string
  icon: string
  routerLink: string
  permissionsNeeded: string[]
}

@Component({
  selector: 'sarbo-main-nav-bar-menu',
  standalone: true,
  imports: [CommonModule, RouterModule, PermissionBasedAccessDirective, TranslateModule],
  templateUrl: './main-nav-bar-menu.component.html',
  styleUrl: './main-nav-bar-menu.component.scss',
})
export class MainNavBarMenuComponent {
  @Input() menuItems: MainNavBarMenuItem[]
  selectedMenuItem: string
  @Output() selectedMenuSubitem = new EventEmitter<MainNavBarMenuSubitem>()
}
