import { Routes } from '@angular/router'
import { PermissionsEnum } from '../shared/models/permission-based-access.model'
import { authGuard } from '../shared/guards/permission.guard'

export const reportsRoutes: Routes = [
  {
    path: 'reports',
    canActivate: [authGuard],
    loadComponent: () => import('../reports/pages/reports/reports.component'),
    data: {
      breadcrumb: 'routes.sales.cashAudit',
      permissionsNeeded: [PermissionsEnum.REPORTS_VIEW],
    },
  },

  {
    path: 'sales-projection',
    canActivate: [authGuard],
    data: {
      breadcrumb: 'routes.reports.salesProjection',
      permissionsNeeded: [PermissionsEnum.SALES_PROJECTIONS_VIEW],
    },
    children: [
      {
        path: '',
        canActivate: [authGuard],
        data: {
          permissionsNeeded: [PermissionsEnum.SALES_PROJECTIONS_VIEW],
        },
        loadComponent: () => import('./pages/sales-projection-history/sales-projection-history.component'),
      },
      {
        path: 'view',
        canActivate: [authGuard],
        data: {
          permissionsNeeded: [PermissionsEnum.SALES_PROJECTIONS_VIEW],
        },
        loadComponent: () => import('./pages/sales-projection-view/sales-projection-view.component'),
      },
    ],
  },
]
