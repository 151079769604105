<div class="flex p-8 flex-col gap-4 text-center max-w-sm w-full h-full justify-evenly">
  @if(data.iconUrl) {<img [src]="data.iconUrl" alt="Dialog Icon" class="mp-10 h-16" />}
  <p class="mt-6" class="text-xl font-bold" [innerHTML]="data.title"></p>
  <p class="mt-4 text-base font-normal" [innerHTML]="data.message"></p>
  <div class="mt-6 gap-4 flex flex-row-reverse w-full">
    @if(data.buttonText01) {<sarbo-button
      [buttonText]="data.buttonText01"
      [mat-dialog-close]="data.buttonText01"
      [color]="data.buttonColor01"
      [component]="data.buttonStyle01"
      [componentClass]="data.componentClass01"
      [focus]="data.buttonFocus01"
    ></sarbo-button
    >} @if(data.buttonText02) {<sarbo-button
      [buttonText]="data.buttonText02"
      [mat-dialog-close]="data.buttonText02"
      [color]="data.buttonColor02"
      [component]="data.buttonStyle02"
      [componentClass]="data.componentClass02"
      [focus]="data.buttonFocus02"
    ></sarbo-button
    >}
  </div>
</div>
